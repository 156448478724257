{
  "name": "hrp-app",
  "version": "1.2.129",
  "scripts": {
    "ng": "ng",
    "trace": "node --trace-warnings ./node_modules/@angular/cli/bin/ng serve",
    "start": "ng serve",
    "start-public": "ng serve --host 0.0.0.0",
    "build": "ng build --configuration development",
    "build-prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test:hrp": "ng test hrp-app",
    "test:graphql": "ng test graphql",
    "test:page": "ng test page",
    "test:core": "ng test core",
    "test:ui": "ng test ui",
    "test:swagger": "ng test swagger",
    "graphql:codegen": "graphql-codegen --config codegen.ts",
    "openapi:humanresource": "ng-openapi-gen --config ng-openapi-gen/humanresources.json",
    "openapi:holidays": "ng-openapi-gen --config ng-openapi-gen/holiday.json",
    "storybook": "ng run hrp-app:storybook",
    "build-storybook": "ng run hrp-app:build-storybook",
    "lint": "ng lint",
    "lint:fix": "eslint --fix --ext .ts,.html src",
    "prepare": "husky install"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/cdk": "^18.0.0",
    "@angular/cli": "^18.0.0",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "@angular/service-worker": "^18.0.0",
    "@apollo/client": "^3.7.14",
    "@jsverse/transloco": "^7.5.0",
    "@ng-icons/core": "^29.10.0",
    "@ng-icons/material-icons": "^29.10.0",
    "@ngneat/effects": "^2.1.2",
    "@ngneat/effects-ng": "^3.1.3",
    "@ngneat/elf": "^2.5.1",
    "@ngneat/elf-entities": "^5.0.1",
    "@ngneat/elf-pagination": "^1.1.0",
    "@ngneat/elf-persist-state": "^1.2.0",
    "@ngneat/elf-requests": "^1.9.2",
    "@ngrx/component-store": "^18.0.0",
    "@ngrx/effects": "^18.0.0",
    "@ngrx/entity": "^18.0.0",
    "@ngrx/operators": "^18.0.2",
    "@ngrx/signals": "^18.0.0",
    "@ngrx/store": "^18.0.0",
    "@ngrx/store-devtools": "^18.0.0",
    "@paragondata/ngx-config": "^18.0.0-beta.24",
    "@paragondata/ngx-signalr": "^18.0.0-beta.12",
    "@paragondata/ngx-ui": "^18.7.1-beta.3",
    "@tailwindcss/container-queries": "^0.1.1",
    "angular-oauth2-oidc": "^17.0.2",
    "angular-oauth2-oidc-jwks": "^17.0.2",
    "apollo-angular": "^7.0.2",
    "date-fns": "^4.1.0",
    "graphql": "^16.6.0",
    "ios-pwa-splash": "^1.0.0",
    "lodash": "^4.17.21",
    "moment": "^2.29.4",
    "ng-openapi-gen": "^0.50.2",
    "rxjs": "~7.8.1",
    "tailwindcss": "^3.3.2",
    "ts-node": "^10.9.1",
    "tslib": "^2.3.0",
    "zod": "^3.23.8",
    "zone.js": "~0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.0",
    "@angular-eslint/builder": "^19.1.0",
    "@angular-eslint/eslint-plugin": "^19.1.0",
    "@angular-eslint/eslint-plugin-template": "^19.1.0",
    "@angular-eslint/template-parser": "^19.1.0",
    "@angular/compiler-cli": "^18.0.0",
    "@chromatic-com/storybook": "^1.9.0",
    "@compodoc/compodoc": "^1.1.25",
    "@graphql-codegen/cli": "^5.0.2",
    "@graphql-codegen/typescript": "^3.0.4",
    "@graphql-codegen/typescript-apollo-angular": "^3.5.6",
    "@graphql-codegen/typescript-operations": "^3.0.4",
    "@ngneat/elf-devtools": "^1.3.0",
    "@ngneat/spectator": "^18.0.0",
    "@ngrx/component-store": "^18.0.0-beta.1",
    "@ngrx/effects": "^18.0.0-beta.1",
    "@ngrx/entity": "^18.0.0-beta.1",
    "@ngrx/store": "^18.0.0-beta.1",
    "@ngrx/store-devtools": "^18.0.0-beta.1",
    "@storybook/addon-docs": "^8.3.3",
    "@storybook/addon-essentials": "^8.3.3",
    "@storybook/addon-interactions": "^8.3.3",
    "@storybook/addon-links": "^8.3.3",
    "@storybook/addon-onboarding": "^8.3.3",
    "@storybook/angular": "^8.3.3",
    "@storybook/blocks": "^8.3.3",
    "@storybook/test": "^8.3.3",
    "@tailwindcss/forms": "^0.5.2",
    "@tailwindcss/line-clamp": "^0.4.0",
    "@types/jasmine": "~4.0.0",
    "@types/lodash": "^4.14.168",
    "@types/node": "^22.9.1",
    "@types/react": "^18.0.27",
    "@types/uuid": "^9.0.2",
    "@typescript-eslint/eslint-plugin": "^8.25.0",
    "@typescript-eslint/parser": "^8.25.0",
    "autoprefixer": "^10.4.7",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^10.0.1",
    "eslint-plugin-prettier": "^5.2.3",
    "husky": "^8.0.0",
    "jasmine-core": "~4.0.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "lint-staged": "^15.4.3",
    "ng-mocks": "^14.13.0",
    "ng-packagr": "^18.0.0",
    "postcss": "^8.4.14",
    "postcss-import": "^15.0.0",
    "storybook": "^8.3.3",
    "typescript": "~5.4.5"
  },
  "optionalDependencies": {
    "@rollup/rollup-linux-x64-gnu": "*"
  }
}

@if ($loading()) {
  @if ($isDesktop()) {
    <par-progress-spinner
      size="54"
      mode="indeterminate"
      class="justify-self-center"
    ></par-progress-spinner>
  }
} @else {
  <h1 class="page-account-information__headline">
    {{ 'account-information' | transloco }}
  </h1>
  <div
    *ngIf="userData$ | async; let userData"
    class="w-full flex flex-col gap-6 desktop:pt-12 mobile:pt-6 tablet:pt-6 desktop:px-12 tablet:px-12 mobile:px-2"
  >
    <div class="page-account-information__username flex flex-col gap-2">
      <p>{{ 'features.account.information.username' | transloco }}</p>
      <p class="hrp-text-body-bold">{{ userData.name }}</p>
    </div>
    <div
      class="page-account-information__roles flex flex-col gap-2"
      *ngFor="let role of userData.roles"
    >
      <p>{{ 'features.account.information.role' | transloco }}</p>
      <p class="hrp-text-body-bold">{{ role.displayLabel }}</p>
    </div>
    <div class="page-account-information__ous flex flex-col gap-2">
      <p>{{ 'features.account.information.available-organisation-units' | transloco }}</p>
      <p *ngFor="let ou of userData?.ou" class="hrp-text-body-bold">
        {{ ou?.displayLabel }}
      </p>
    </div>
    <div class="flex flex-col gap-2">
      <p>{{ 'features.account.information.access-token' | transloco }}</p>

      <div class="flex desktop:flex-row tablet:flex-row mobile:flex-col gap-2">
        <div class="flex flex-col">
          @if ($accessTokenExpiresAt()) {
            <p class="page-account-information__token-valid hrp-text-body-bold">
              {{
                'features.account.information.access-token-valid'
                  | transloco: { date: $accessTokenExpiresAt() | date: 'medium' }
              }}
            </p>
          }
          <p class="page-account-information__token-expires hrp-text-body-bold">
            {{
              'features.account.information.access-token-expiry'
                | transloco: { date: $accessTokenExpiresIn() }
            }}
          </p>
        </div>
        <button
          type="button"
          (click)="refreshAccessToken()"
          class="page-account-information__token-refresh h-full desktop:self-center tablet:self-center mobile:self-end inline-block tertiary small desktop:ml-20 tablet:ml-20"
        >
          {{ 'features.account.information.access-token-renew' | transloco }}
        </button>
      </div>
    </div>
    <div class="page-account-information__app-version flex flex-col gap-2">
      <p>{{ 'features.account.information.app-version' | transloco }}</p>
      <p class="hrp-text-body-bold">{{ appVersion }}</p>
    </div>
  </div>
}

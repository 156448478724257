import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ResponsiveService } from '@core/services';
import { PageAccountSidebarComponent } from './sidebar/sidebar.component';

@Component({
  selector: 'page-account',
  templateUrl: 'account.component.html',
  styleUrls: ['account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, PageAccountSidebarComponent],
  standalone: true,
})
export class PageAccountComponent {
  private _responsive = inject(ResponsiveService);

  get $isDesktop() {
    return this._responsive.$isDesktop;
  }
}
